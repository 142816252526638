import(/* webpackMode: "eager", webpackExports: ["HomePageRedirectLogic"] */ "/app/apps/web/src/app/home-page-redirect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AmplitudePageLoadTrackingServerComponentOnly"] */ "/app/apps/web/src/components/amplitude/amplitude-page-load-tracking-server-component-only.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Icon"] */ "/app/apps/web/src/components/icon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SpinnerIcon"] */ "/app/apps/web/src/components/icons/spinner-icon/spinner-icon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RcLink"] */ "/app/apps/web/src/components/rc-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AvailableToRedeem"] */ "/app/apps/web/src/features/available-to-redeem/available-to-redeem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CampaignBannerV2"] */ "/app/apps/web/src/features/campaigns/campaign-banner-v2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CarouselV2"] */ "/app/apps/web/src/features/home-page/components/carousel/carousel-v2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/src/features/home-page/components/copy/copy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeaturedProducts"] */ "/app/apps/web/src/features/home-page/components/featured-products/featured-products.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroBannerAndQuickLinksV2"] */ "/app/apps/web/src/features/home-page/components/hero-banner-and-quick-links-v2/hero-banner-and-quick-links-v2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/app/apps/web/src/features/home-page/components/hero/homepage-hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MarketingBanner"] */ "/app/apps/web/src/features/home-page/components/marketing-banner/marketing-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/src/features/home-page/components/quick-link/quick-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/src/features/home-page/components/quick-links/quick-links.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PointsBalanceWidget"] */ "/app/apps/web/src/features/home-page/components/widgets/points-balance-widget.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["M2020OffersBanner"] */ "/app/apps/web/src/features/recommendation/components/m2020-offers-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RecommendedBannerWithDataM2020"] */ "/app/apps/web/src/features/recommendation/components/m2020-recommended-banner-with-data.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RecommendedForUserBanner"] */ "/app/apps/web/src/features/recommendation/components/recommended-for-user-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RedeemAgainBanner"] */ "/app/apps/web/src/features/redeem-again/redeem-again.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/src/root-provider/tenant-hub-provider/tenant-hub-page-title-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@unleash+nextjs@1.4.3_next@14.2.13_@babel+core@7.22.1_@opentelemetry+api@1.9.0_react-dom@18.2_ppcfum7zixqnqb2hkh3jpu2mzq/node_modules/@unleash/nextjs/dist/FlagProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@unleash+nextjs@1.4.3_next@14.2.13_@babel+core@7.22.1_@opentelemetry+api@1.9.0_react-dom@18.2_ppcfum7zixqnqb2hkh3jpu2mzq/node_modules/@unleash/nextjs/dist/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.17.2_next@14.2.13_@babel+core@7.22.1_@opentelemetry+api@1.9.0_react-dom@18.2.0_re_iltuvqoadlgklyvikeex6cesie/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.13_@babel+core@7.22.1_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.3.1__react@18.3.1_sass@1.69.5/node_modules/next/dist/client/link.js");
