import { FormatterOption } from '@/hooks/utils/use-date-time-formatter';
import { DateFormatterOptions } from 'react-aria';

// Full date formatter
const DEFAULT_DATE_FORMAT: DateFormatterOptions = {
  month: 'short',
  day: '2-digit',
  year: 'numeric',
};

export const DEFAULT_DATE_FORMAT_OPTION: FormatterOption<'date'> = {
  name: 'date',
  formatOption: DEFAULT_DATE_FORMAT,
};

const DEFAULT_DATE_UTC_FORMAT: DateFormatterOptions = {
  month: 'short',
  day: '2-digit',
  year: 'numeric',
  timeZone: 'UTC',
};

export const DEFAULT_DATE_UTC_FORMAT_OPTION: FormatterOption<'dateUTC'> = {
  name: 'dateUTC',
  formatOption: DEFAULT_DATE_UTC_FORMAT,
};

const SUMMARY_CARD_DATE_FORMAT: DateFormatterOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

export const DATE_TIME_SHORT_FORMAT_OPTION: FormatterOption<'dateTimeShort'> = {
  name: 'dateTimeShort',
  formatOption: SUMMARY_CARD_DATE_FORMAT,
};

const DATE_TIME_SHORT_UTC_FORMAT: DateFormatterOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  timeZone: 'utc',
};

export const DATE_TIME_SHORT_UTC_FORMAT_OPTION: FormatterOption<'dateTimeShortUTC'> =
  {
    name: 'dateTimeShortUTC',
    formatOption: DATE_TIME_SHORT_UTC_FORMAT,
  };

const TWO_DIGIT_DATE_FORMAT: DateFormatterOptions = {
  day: '2-digit',
};

export const TWO_DIGIT_DATE_FORMAT_OPTION: FormatterOption<'twoDigitDate'> = {
  name: 'twoDigitDate',
  formatOption: TWO_DIGIT_DATE_FORMAT,
};

const TWO_DIGIT_DATE_SHORT_FORMAT: DateFormatterOptions = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
};

export const TWO_DIGIT_DATE_SHORT_FORMAT_OPTION: FormatterOption<'twoDigitDateFormatShort'> =
  {
    name: 'twoDigitDateFormatShort',
    formatOption: TWO_DIGIT_DATE_SHORT_FORMAT,
  };

const TWO_DIGIT_DATE_SHORT_UTC_FORMAT: DateFormatterOptions = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
  timeZone: 'utc',
};

export const TWO_DIGIT_DATE_SHORT_UTC_FORMAT_OPTION: FormatterOption<'twoDigitDateFormatShortUTC'> =
  {
    name: 'twoDigitDateFormatShortUTC',
    formatOption: TWO_DIGIT_DATE_SHORT_UTC_FORMAT,
  };

const DAY_MONTH_FORMAT: DateFormatterOptions = {
  month: 'short',
  day: '2-digit',
};

export const DAY_MONTH_FORMAT_OPTION: FormatterOption<'dayMonth'> = {
  name: 'dayMonth',
  formatOption: DAY_MONTH_FORMAT,
};

const SHORT_MONTH_FORMAT: DateFormatterOptions = {
  month: 'short',
};

export const SHORT_MONTH_FORMAT_OPTION: FormatterOption<'shortMonth'> = {
  name: 'shortMonth',
  formatOption: SHORT_MONTH_FORMAT,
};

const DAY_ONLY_UTC_FORMAT: DateFormatterOptions = {
  day: '2-digit',
  timeZone: 'UTC',
};

export const DAY_ONLY_UTC_FORMAT_OPTION: FormatterOption<'dayOnlyUtc'> = {
  name: 'dayOnlyUtc',
  formatOption: DAY_ONLY_UTC_FORMAT,
};

const MONTH_YEAR_UTC_FORMAT: DateFormatterOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  timeZone: 'UTC',
};

export const MONTH_YEAR_UTC_FORMAT_OPTION: FormatterOption<'monthYearUtc'> = {
  name: 'monthYearUtc',
  formatOption: MONTH_YEAR_UTC_FORMAT,
};

const NUMERIC_YEAR_FORMAT: DateFormatterOptions = {
  year: 'numeric',
};

export const NUMERIC_YEAR_FORMAT_OPTION: FormatterOption<'numericYear'> = {
  name: 'numericYear',
  formatOption: NUMERIC_YEAR_FORMAT,
};

const CANCELLATION_DATE_UTC_FORMAT: DateFormatterOptions = {
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  year: 'numeric',
  minute: 'numeric',
  hourCycle: 'h12',
  timeZone: 'UTC',
};

const POINTS_BALANCE_FORMAT: DateFormatterOptions = {
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  year: 'numeric',
  minute: 'numeric',
  hour12: false,
};

export const POINTS_BALANCE_FORMAT_OPTION: FormatterOption<'pointsBalance'> = {
  name: 'pointsBalance',
  formatOption: POINTS_BALANCE_FORMAT,
};

export const CANCELLATION_DATE_UTC_FORMAT_OPTION: FormatterOption<'cancellationDateUtc'> =
  {
    name: 'cancellationDateUtc',
    formatOption: CANCELLATION_DATE_UTC_FORMAT,
  };

// Weekday formatter

const WEEKDAY_SHORT_FORMAT: DateFormatterOptions = {
  weekday: 'short',
};

export const WEEKDAY_SHORT_FORMAT_OPTION: FormatterOption<'weekdayShort'> = {
  name: 'weekdayShort',
  formatOption: WEEKDAY_SHORT_FORMAT,
};

const WEEKDAY_LONG_FORMAT: DateFormatterOptions = {
  weekday: 'long',
};

export const WEEKDAY_LONG_FORMAT_OPTION: FormatterOption<'weekdayLong'> = {
  name: 'weekdayLong',
  formatOption: WEEKDAY_LONG_FORMAT,
};

export const DEFAULT_WEEKDAY_FORMAT_OPTION: FormatterOption<'weekday'> = {
  name: 'weekday',
  formatOption: WEEKDAY_SHORT_FORMAT,
};

const DEFAULT_TIME_FORMAT: DateFormatterOptions = {
  hour: '2-digit',
  minute: '2-digit',
};

export const DEFAULT_TIME_FORMAT_OPTION: FormatterOption<'time'> = {
  name: 'time',
  formatOption: DEFAULT_TIME_FORMAT,
};

const CAR_SEARCHBAR_TIME_FORMAT: DateFormatterOptions = {
  hour: 'numeric',
  minute: '2-digit',
};

export const CAR_SEARCH_BAR_TIME_FORMAT_OPTION: FormatterOption<'carSearchBarTime'> =
  {
    name: 'carSearchBarTime',
    formatOption: CAR_SEARCHBAR_TIME_FORMAT,
  };

const CAR_SEARCHBAR_DATE_FORMAT: DateFormatterOptions = {
  month: 'short',
  day: 'numeric',
};

export const CAR_SEARCH_BAR_DATE_FORMAT_OPTION: FormatterOption<'carSearchBarDate'> =
  {
    name: 'carSearchBarDate',
    formatOption: CAR_SEARCHBAR_DATE_FORMAT,
  };

const MONTH_LONG_YEAR_NUMERIC_FORMAT: DateFormatterOptions = {
  month: 'long',
  year: 'numeric',
};

export const MONTH_LONG_YEAR_NUMERIC_FORMAT_OPTION: FormatterOption<'monthLongYearNumeric'> =
  {
    name: 'monthLongYearNumeric',
    formatOption: MONTH_LONG_YEAR_NUMERIC_FORMAT,
  };

const CAMPAIGN_CARD_DATE_TIME_FORMAT: DateFormatterOptions = {
  month: 'short',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
  timeZoneName: 'short',
};

export const CAMPAIGN_CARD_DATE_TIME_FORMAT_OPTION: FormatterOption<'campaignCardDate'> =
  {
    name: 'campaignCardDate',
    formatOption: CAMPAIGN_CARD_DATE_TIME_FORMAT,
  };

const PICKUP_DROP_OFF_TIME_FORMAT: DateFormatterOptions = {
  hour: 'numeric',
  minute: '2-digit',
};

export const PICKUP_DROP_OFF_TIME_FORMAT_OPTION: FormatterOption<'pickupDropOffTime'> =
  {
    name: 'pickupDropOffTime',
    formatOption: PICKUP_DROP_OFF_TIME_FORMAT,
  };

const TIME_ONLY_FORMAT: DateFormatterOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
};

export const TIME_ONLY_FORMAT_OPTION: FormatterOption<'timeOnly'> = {
  name: 'timeOnly',
  formatOption: TIME_ONLY_FORMAT,
};
